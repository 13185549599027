import React from 'react';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';

class Popover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverVisible: false,
    };
  }

  showPopover() {
    this.setState({ popoverVisible: true });
  }

  hidePopover() {
    this.setState({ popoverVisible: false });
  }

  render() {
    const { popoverVisible } = this.state;
    const { children, buttonTitle, buttonHoverColor } = this.props;

    return (
      <div className='-mt-px' onMouseLeave={this.hidePopover.bind(this)}>
        <Manager>
          <Reference>
            {({ ref }) => (
              <button
                type='button'
                ref={ref}
                className={`cursor-default font-soft font-semibold text-sm text-blue-900 leading-none inline-block pb-1 border-transparent hover:${buttonHoverColor}`}
                onMouseEnter={this.showPopover.bind(this)}
              >
                {buttonTitle}
              </button>
            )}
          </Reference>

          {
            popoverVisible
            && (
              <Popper
                placement='bottom-start'
                modifiers={{
                  offset: {
                    fn(data) {
                      // eslint-disable-next-line no-param-reassign
                      data.offsets.popper.top += 2;
                      return data;
                    },
                  },
                }
              }
              >
                {({ ref, style, placement, arrowProps }) => (
                  <div
                    ref={ref}
                    style={style}
                    data-placement={placement}
                    className='bg-white p-4 rounded shadow-md'
                  >
                    {children}
                    <div ref={arrowProps.ref} style={arrowProps.style} />
                  </div>
                )}
              </Popper>
            )
          }
        </Manager>
      </div>
    );
  }
}

Popover.propTypes = {
  buttonTitle: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Popover.defaultProps = {
  buttonTitle: '',
  buttonHoverColor: 'text-white',
};


export default Popover;
