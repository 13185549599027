import React from 'react';
import sequenceLogo from '../images/sequence-logo-white.svg';

const Footer = () => (
  <div className='bg-blue-500'>
    <div className='container mx-auto py-16 px-8 flex flex-col lg:flex-row'>
      <div className='flex-none lg:w-1/2 lg:pr-8'>
        <img className='w-48 mb-4' src={sequenceLogo} alt='sequencebio' />
        <p className='font-sans text-sm text-white mb-4'>
          St. John’s based biotechnology company Sequence Bio is leading the NL
          Genome Project.
        </p>

        <p className='font-sans text-sm text-white'>
          Sequence Bio’s Senior Director of Data Science and Analytics, Bari Ballew, PhD, is the Principal Investigator of the study. Sequence Bio’s Director of Research, Gerald Mugford, PhD, and Dr. Dennis O’Keefe are the Co-Principal Investigators.
        </p>
      </div>

      <div className='flex-1 mt-8 lg:mt-0 lg:w-1/4 lg:ml-4'>
        <h5 className='font-sans font-bold text-white leading-tight mb-4'>
          Questions about your rights as a participant in a research study?
        </h5>

        <p className='font-sans text-sm text-white mb-4 leading-snug'>
          Contact the HREA Ethics Office. They are not involved with running the study.
        </p>

        <p className='font-sans text-sm text-white mb-4 leading-snug'>
          <a className='text-white mr-1' href='tel:+17097776974'>
            (709) 864-8871
          </a>
          |
          <a className='underline text-yellow ml-1' href='mailto:info@hrea.ca'>
            info@hrea.ca
          </a>
        </p>

        <p className='font-sans text-sm text-white mb-4 leading-snug'>
          <a className='underline text-yellow ml-1' href='/privacy_policy'>
            Privacy policy
          </a>
        </p>
      </div>
    </div>

    <script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={
        {
          __html: "(function(s,u,m,o,j,v){j=u.createElement(m);v=u.getElementsByTagName(m)[0];j.async=1;j.src=o;j.dataset.sumoSiteId='cf5fb9f9de04ce6395b03efe103837d5b0a4155ac1f83bb36c15a43d343dfcec';v.parentNode.insertBefore(j,v)})(window,document,'script','//load.sumo.com/')",
        }
      }
    />
  </div>
);

export default Footer;
