import { Link } from 'gatsby';
import React from 'react';
import ReactGA from 'react-ga';
import { slide as Menu } from 'react-burger-menu';
import Popover from './popover';
import logoWhite from '../images/nl-genome-logo-white.svg';
import logoBlue from '../images/nl-genome-logo-blue.svg';
import MailingListModal from './home/mailing-list-modal';


class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      scrollPosition: 0,
      navBarVisible: false,
    };
    // This binding is necessary to make `this` work in the callback
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-undef
    window.removeEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll() {
    // eslint-disable-next-line no-undef
    const windowPosition = window.pageYOffset;
    const { scrollPosition } = this.state;

    if (scrollPosition > windowPosition) {
      this.setState({
        scrollPosition: windowPosition,
      }, this.showNavBar());
    } else if (scrollPosition <= windowPosition) {
      this.setState({
        scrollPosition: windowPosition,
      }, this.hideNavBar());
    }
  }

  showNavBar() {
    const { scrollPosition, navBarVisible } = this.state;

    if (scrollPosition > 80) {
      if (!navBarVisible) {
        this.setState({ navBarVisible: true });
      }
    } else {
      this.hideNavBar();
    }
  }

  hideNavBar() {
    const { navBarVisible } = this.state;

    if (navBarVisible) {
      this.setState({ navBarVisible: false });
    }
  }

  toggleModal() {
    this.setState(state => ({
      modalVisible: !state.modalVisible,
    }));
  }

  render() {
    const { modalVisible, navBarVisible } = this.state;

    return (
      <>
        <div className='w-full absolute top-0 z-10'>
          <div className='container mx-auto px-8 flex justify-between items-center'>
            <div className='flex-none self-start'>
              <Link to='/'>
                <img className='w-16 lg:w-20 border-t-4 border-white pt-2' src={logoWhite} alt='Sequence Bio' />
              </Link>
            </div>

            <div className='hidden lg:flex flex-shrink items-center'>
              <a
                href='/take-part'
                className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 mr-4 lg:mr-6 border-transparent hover:text-white hover:border-yellow'
              >
                Take Part
              </a>

              <a
                href='/how-it-works'
                className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 mr-4 lg:mr-6 border-transparent hover:text-white hover:border-yellow'
              >
                How It Works
              </a>

              <div className='lg:mr-6'>
                <Popover buttonTitle='Our Science'>
                  <ul className=''>
                    <li className='mb-2'>
                      <a
                        href='/our-science'
                        className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 border-transparent hover:text-blue hover:border-yellow'
                      >
                        Our Science
                      </a>
                    </li>
                    <li>
                      <a
                        href='/our-team'
                        className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 border-transparent hover:text-blue hover:border-yellow'
                      >
                        Our Researchers and Team
                      </a>
                    </li>
                  </ul>
                </Popover>
              </div>

              <a
                href='/your-results'
                className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 mr-4 lg:mr-6 border-transparent hover:text-white hover:border-yellow'
              >
                Your Results
              </a>

              <a
                href='/privacy'
                className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 mr-4 lg:mr-6 border-transparent hover:text-white hover:border-yellow'
              >
                Privacy
              </a>

              <Popover buttonTitle='Ask Us Anything'>
                <ul className=''>
                  <li className='mb-2'>
                    <a
                      href='/faq'
                      className='font-soft font-semibold text-sm text-blue-900 leading-none mt-1 border-b-2 pb-1/2 border-transparent hover:text-blue hover:border-yellow'
                    >
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a
                      href='/contact'
                      className='font-soft font-semibold text-sm text-blue-900 leading-none mt-1 border-b-2 pb-1/2 border-transparent hover:text-blue hover:border-yellow'
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </Popover>
            </div>

            <div className='flex-shrink flex items-center'>
              <button
                type='button'
                className='button py-3 px-5 font-semibold text-sm hidden lg:inline-block'
                onClick={() => {
                  ReactGA.event({
                    category: 'CTAs',
                    action: 'Click',
                    label: 'Keep in touch',
                  });
                  this.toggleModal();
                }}
              >
                Keep in Touch
              </button>
            </div>
          </div>
          <MailingListModal
            modalVisible={modalVisible}
            toggleModal={this.toggleModal}
          />
        </div>

        <div
          className='hidden lg:block fixed w-full top-0 z-10 transition-transform transition-250 transition-linear'
          style={{
            pointerEvents: `${navBarVisible ? 'auto' : 'none'}`,
            transform: `translate(0, ${navBarVisible ? '0' : '-140px'})`,
            willChange: 'transform',
          }}
        >
          <div className='container mx-auto px-8 pt-2 pb-3 flex justify-between items-center bg-white shadow-lg lg:rounded-b'>
            <div className='flex-none self-start'>
              <Link to='/'>
                <img className='w-16 lg:w-20  border-t-4 border-blue pt-2 -mt-2' src={logoBlue} alt='Sequence Bio' />
              </Link>
            </div>

            <div className='flex-shrink flex items-center'>
              <a
                href='/take-part'
                className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 mr-4 lg:mr-6 border-transparent hover:text-blue hover:border-yellow'
              >
                Take Part
              </a>

              <a
                href='/how-it-works'
                className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 mr-4 lg:mr-6 border-transparent hover:text-blue hover:border-yellow'
              >
                How It Works
              </a>

              <div className='lg:mr-6'>
                <Popover
                  buttonTitle='Our Science'
                  buttonHoverColor='text-blue'
                >
                  <ul className=''>
                    <li className='mb-2'>
                      <a
                        href='/our-science'
                        className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 border-transparent hover:text-blue hover:border-yellow'
                      >
                        Our Science
                      </a>
                    </li>
                    <li>
                      <a
                        href='/our-team'
                        className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 border-transparent hover:text-blue hover:border-yellow'
                      >
                        Our Researchers and Team
                      </a>
                    </li>
                  </ul>
                </Popover>
              </div>

              <a
                href='/your-results'
                className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 mr-4 lg:mr-6 border-transparent hover:text-blue hover:border-yellow'
              >
                Your Results
              </a>

              <a
                href='/privacy'
                className='font-soft font-semibold text-sm text-blue-900 leading-none inline-block mt-1 border-b-2 pb-1 mr-4 lg:mr-6 border-transparent hover:text-blue hover:border-yellow'
              >
                Privacy
              </a>

              <Popover
                buttonTitle='Ask Us Anything'
                buttonHoverColor='text-blue'
              >
                <ul className=''>
                  <li className='mb-2'>
                    <a
                      href='/faq'
                      className='font-soft font-semibold text-sm text-blue-900 leading-none mt-1 border-b-2 pb-1/2 border-transparent hover:text-blue hover:border-yellow'
                    >
                      FAQ
                    </a>
                  </li>

                  <li>
                    <a
                      href='/contact'
                      className='font-soft font-semibold text-sm text-blue-900 leading-none mt-1 border-b-2 pb-1/2 border-transparent hover:text-blue hover:border-yellow'
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </Popover>
            </div>

            <div className='flex-shrink flex items-center'>
              <button
                type='button'
                className='button py-3 px-5 font-semibold text-sm hidden lg:inline-block'
                onClick={() => {
                  ReactGA.event({
                    category: 'CTAs',
                    action: 'Click',
                    label: 'Keep in touch',
                  });
                  this.toggleModal();
                }}
              >
                Keep in Touch
              </button>
            </div>

          </div>
        </div>

        <Menu right>
          <a
                href='/take-part'
                className='font-soft font-semibold text-blue-900 leading-none inline-block hover:text-blue mb-4'
              >
                Take Part
          </a>

          <a
            href='/how-it-works'
            className='font-soft font-semibold text-blue-900 leading-none inline-block hover:text-blue mb-4'
          >
            How It Works
          </a>

          <a
            href='/our-science'
            className='font-soft font-semibold text-blue-900 leading-none inline-block hover:text-blue mb-4'
          >
            Our Science
          </a>

          <a
            href='/our-team'
            className='font-soft font-semibold text-blue-900 leading-none inline-block hover:text-blue mb-4'
          >
            Our Researchers and Team
          </a>

          <a
            href='/your-results'
            className='font-soft font-semibold text-blue-900 leading-none inline-block hover:text-blue mb-4'
          >
            Your Results
          </a>

          <a
            href='/privacy'
            className='font-soft font-semibold text-blue-900 leading-none inline-block hover:text-blue mb-4'
          >
            Privacy
          </a>

          <a
            href='/faq'
            className='font-soft font-semibold text-blue-900 leading-none inline-block hover:text-blue mb-4'
          >
            FAQ
          </a>

          <a
            href='/contact'
            className='font-soft font-semibold text-blue-900 leading-none inline-block hover:text-blue mb-4'
          >
            Contact
          </a>

          <button
            type='button'
            className='button py-3 px-5 font-semibold text-sm mt-16 ml-6'
            onClick={() => {
              ReactGA.event({
                category: 'CTAs',
                action: 'Click',
                label: 'Keep in touch',
              });
              this.toggleModal();
            }}
          >
            Keep in Touch
          </button>
        </Menu>
      </>
    );
  }
}


export default Nav;
