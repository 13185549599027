/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Footer from './footer';
import Nav from './nav';
import { Helmet } from 'react-helmet';
import favicon from '../../static/favicons/NLGP-Favicon.ico';

// import Header from './header';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={_data => (
      <>
      <Helmet>
        <link rel="icon" href={favicon}/>
      </Helmet>

        <noscript>
          <iframe
            title='Google Tag Manager'
            src='https://www.googletagmanager.com/ns.html?id=GTM-KPRTBG3'
            height='0'
            width='0'
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>

        <Nav />

        <main>{children}</main>

        <Footer />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
